<template>
  <div>
    <div class="border-btm" style="padding-bottom: 32px">
      <div :class="{ border_error: isSubmitting && !selectedjuri[0].j }">
        <p class="title">
          Main Jurisdiction and Court:<span class="text-danger">*</span>
        </p>
        <div class="main-juris">
          <div
            v-for="(i, j) in jurisdictions"
            :key="`main-${j}`"
            :class="j === juris && 'active'"
            @click="juris = j"
          >
            <img :src="i" :alt="j" />
            <p>{{ j }}</p>
          </div>
        </div>
        <div
          :class="[
            'main-court',
            juris && 'active',
            { choose_error: isSubmitting && juris && !courts.length },
          ]"
          @click="showCourtModal"
        >
          <p v-if="!courts.length">
            {{
              juris
                ? "Choose the appropriate court for this case"
                : "Please Select a Jurisdiction"
            }}
          </p>
          <span
            v-else
            v-for="(c, id) in courts"
            :key="`main-court-${id}`"
            class="court-badge"
            @click="removeCourt(id)"
          >
            <p class="court-name">
              {{ truncateName(c?.name ?? "All Courts & Institutions") }}
            </p>
            <span class="material-symbols-rounded">close</span>
          </span>
          <span class="material-symbols-rounded fill">filter_alt</span>
        </div>
      </div>

      <template v-if="juris">
        <p class="title ex-margin">
          Research Jurisdiction and Court:<span class="sm">{{
            juris !== "" && courts.length > 0
              ? ""
              : "*Select Main Jurisdiction first"
          }}</span>
        </p>

        <JurisdictionCourtList
          ref="jurisdictionCourtList"
          :options="rJurisdictions"
          @input="rJurisCourt = $event"
          @update="SelectedUpdate"
          :isSubmitting="isSubmitting"
          :is-able="ableToSelect"
        />
      </template>

      <CourtSelect
        name="PretrialMainCourt"
        :config="mainCourtConfig"
        @input="courts = $event"
      />
    </div>
    <template>
      <div
        :class="{ disabled: !juris, 'border-btm': juris !== 'MY' }"
        :style="juris !== 'MY' ? { 'padding-bottom': '20px' } : {}"
      >
        <LegalTeamAndJudiciary
          :country="juris"
          @updateLegalTeam="handleLegalTeamUpdate"
          @updateJudiciary="handleJudiciaryUpdate"
          :shouldReset="shouldReset"
        />
      </div>
    </template>
  </div>
</template>

<script>
import country from "../../../assets/country/index";
import JurisdictionCourtList from "../../../components/input/JurisdictionCourtList.vue";
import CourtSelect from "../../../components/input/CourtSelect.vue";
import LegalTeamAndJudiciary from "./LegalTeamAndJudiciary.vue";
export default {
  props: {
    isSubmitting: Boolean,
    shouldReset: Boolean,
  },
  components: {
    JurisdictionCourtList,
    CourtSelect,
    LegalTeamAndJudiciary,
  },

  data() {
    return {
      showModal: true,
      mainCourtConfig: { j: null, c: null },

      jurisdictions: country,
      juris: null,
      courts: [],
      legalTeam: [],
      judiciary: [],

      rJurisCourt: [{ j: null, c: [] }],
      selectedjuri: [{ j: null, c: [] }],
      selected: [{ j: null, c: [] }],
      jurisdiction: {},
      selectedCountry: "",
    };
  },

  computed: {
    ableToSelect() {
      return this.juris !== "" && this.courts.length > 0 ? true : false;
    },
    rJurisdictions() {
      return Object.fromEntries(
        Object.entries(this.jurisdictions).filter(
          (j) => ![this.juris, "USA"].includes(j[0])
        )
      );
    },
  },

  watch: {
    juris(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.courts = [];
        this.resetJurisdictionCourtList();
        this.updateSelectedJuri();
      }
    },
    courts: {
      handler() {
        this.updateSelectedJuri();
      },
      deep: true,
    },
    shouldReset(newVal) {
      if (newVal) {
        this.resetValues();
      }
    },
    jurisdiction: {
      handler() {
        this.$emit('input', this.jurisdiction);
      },
      deep: true
    }
  },

  methods: {
    showCourtModal() {
      if (this.showModal && this.juris) {
        this.$set(this, "mainCourtConfig", {
          j: this.juris,
          c: this.courts,
        });
        setTimeout(() => this.$bvModal.show("PretrialMainCourt"), 0);
      }

      this.showModal = true;
    },
    truncateName(name) {
      const maxLength = 50;
      if (name.length > maxLength) {
        return name.substring(0, maxLength) + "...";
      }
      return name;
    },
    removeCourt(id) {
      this.courts.splice(id, 1);
      this.showModal = false;
    },
    updateSelectedJuri() {
      this.jurisdiction = {};
      this.selectedjuri[0] = { j: this.juris, c: this.courts };
      if (this.juris) {
        this.$set(this.jurisdiction, this.juris, this.courts);
      }
    },
    SelectedUpdate(updatedSelected) {
      this.selected = updatedSelected;
      this.selected.forEach((item) => {
        if (item.j) {
          this.$set(this.jurisdiction, item.j, item.c);
        }
      });
    },
    handleLegalTeamUpdate(updatedLegalTeam) {
      this.legalTeam = updatedLegalTeam;
      this.$emit("legal-input", this.legalTeam);
    },
    handleJudiciaryUpdate(updatedJudiciary) {
      this.judiciary = updatedJudiciary;
      this.$emit("judiciary-input", this.judiciary);
    },
    resetValues() {
      // Clear all the fields and emit the necessary events
      this.jurisdiction = {};
      this.legalTeam = [];
      this.judiciary = [];
      this.juris = null;
      this.courts = [];

      // Emit cleared data
      this.$emit("legal-input", []);
      this.$emit("judiciary-input", []);
      this.$emit("input", {});
    },
    resetJurisdictionCourtList() {
      this.$refs.jurisdictionCourtList.reset();
    }
  },
};
</script>

<style scoped>
.title {
  margin: 32px 0px 16px 0px;
  font-weight: 500;

  &.ex-margin {
    margin-top: 32px;
  }
}

p {
  margin: 0px;
}

.court-badge {
  margin-right: 6px;
  padding: 0px 12px;
  min-height: 32px;
  border-radius: 16px;
  background-color: var(--mid-grey);
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .court-name {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    margin-left: 8px;
    font-size: 18px;
  }
}
</style>

<!-- Main Jurisdiction & Court -->
<style scoped>
.main-juris {
  display: flex;

  > div {
    margin-right: 10px;
    padding: 0px 20px;
    width: calc(calc(100% - 60px) / 7);
    height: 40px;
    border-radius: 6px;
    border: 1px solid var(--light-grey);
    background-color: var(--interface-grey);
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #e7ecf366;
      border-color: #0e448599;

      p {
        color: #0e448599 !important;
      }
    }

    &.active {
      background-color: var(--light-blue);
      border-color: var(--primary);

      p {
        color: var(--primary) !important;
      }
    }

    img {
      height: 24px;
      aspect-ratio: 1;
      margin-right: 6px;
    }

    p {
      color: var(--dark-grey) !important;
    }
  }

  > div:last-child {
    margin: 0px;
  }
}

.main-court {
  margin-top: 10px;
  padding: 4px 10px;
  min-height: 40px;
  border-radius: 6px;
  border: 1px solid var(--light-grey);
  background-color: var(--interface-grey);
  display: flex;
  align-items: center;
  cursor: not-allowed;

  &.active {
    cursor: pointer;
  }

  &.active:hover {
    background-color: var(--light-grey);
    border-color: var(--mid-grey);
  }

  > p {
    color: var(--dark-grey) !important;
  }

  .material-symbols-rounded.fill {
    margin: 0px 6px 0px auto;
    width: 22px;
    height: 22px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.border-btm {
  border-bottom: 1px solid var(--mid-grey);
}
.disabled {
  pointer-events: none;
}
.sm {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--status-red);
}
.border_error {
  outline: 1px solid var(--status-red);
  border-radius: 8px;
}
.choose_error {
  outline: 1px solid var(--status-red);
  background: #ff4d4f1a;
}
</style>
